export const profileTextFieldsConfig = {
  generalDetails: [
    {
      capitalizeValue: true,
      fieldName: 'first_name',
      countrySpecificField: ['UK', 'IE'],
      hasIcon: true,
      icon: 'CHECKMARK',
      isReadOnly: true,
      label: 'profile:profileTab.firstName',
      type: 'text',
      validations: ['isRequired', 'lettersOnly']
    },
    {
      capitalizeValue: true,
      fieldName: 'first_name',
      countrySpecificField: ['BE', 'DE', 'FR'],
      hasIcon: true,
      icon: 'CHECKMARK',
      label: 'profile:profileTab.firstName',
      type: 'text',
      validations: ['isRequired', 'lettersOnly']
    },
    {
      capitalizeValue: true,
      fieldName: 'last_name',
      countrySpecificField: ['UK', 'IE'],
      hasIcon: true,
      icon: 'CHECKMARK',
      isReadOnly: true,
      label: 'profile:profileTab.lastName',
      type: 'text',
      validations: ['isRequired', 'lettersOnly']
    },
    {
      capitalizeValue: true,
      fieldName: 'last_name',
      countrySpecificField: ['BE', 'DE', 'FR'],
      hasIcon: true,
      icon: 'CHECKMARK',
      label: 'profile:profileTab.lastName',
      type: 'text',
      validations: ['isRequired', 'lettersOnly']
    },
    {
      capitalizeValue: false,
      className: 'display-none',
      fieldName: 'email',
      hasIcon: true,
      icon: 'CHECKMARK',
      label: 'profile:profileTab.email',
      type: 'email',
      validations: ['isRequired', 'emailValidation']
    },
    {
      capitalizeValue: false,
      className: 'display-none',
      fieldName: 'phone_number',
      hasIcon: true,
      icon: 'CHECKMARK',
      label: 'profile:profileTab.phone',
      type: 'number',
      validations: ['isRequired', 'phoneNumber']
    }
  ],
  identity: {
    birthCertificate: [
      {
        capitalizeValue: false,
        className: 'custom-date-input',
        fieldName: 'date_of_birth',
        hasIcon: false,
        label: 'profile:identityTab.dateOfBirth',
        dateRange: { min: null, max: 'today' },
        type: 'date',
        validations: ['isRequired', 'maxDateValidation']
      },
      {
        capitalizeValue: false,
        fieldName: 'gender',
        hasIcon: false,
        label: 'profile:identityTab.selectGender',
        type: 'select',
        selectTranslationKey: 'profile:identityTab',
        validations: ['isRequired']
      },
      {
        capitalizeValue: true,
        fieldName: 'nationality',
        hasIcon: false,
        inputId: 'userNationality-dropdown',
        label: 'profile:identityTab.nationality',
        placeholder: 'profile:identityTab.nationalityPlaceholder',
        type: 'text',
        validations: ['isRequired', 'isObjectRequired'],
        value: 'nationality.translated_nationality',
        valueProperty: true
      },
      {
        capitalizeValue: true,
        countrySpecificField: ['IE', 'UK'],
        fieldName: 'country_of_citizenship',
        hasIcon: false,
        inputId: 'countryOfCitizenship-dropdown',
        label: 'profile:identityTab.countryOfCitizenship',
        placeholder: 'profile:identityTab.countryOfCitizenshipPlaceholder',
        type: 'text',
        validations: ['isRequired', 'isObjectRequired'],
        value: 'country_of_citizenship.translated_name',
        valueProperty: true
      },
      {
        capitalizeValue: true,
        fieldName: 'country_of_birth',
        hasIcon: false,
        inputId: 'birthCountry-dropdown',
        label: 'profile:identityTab.birthCountry',
        placeholder: 'profile:identityTab.birthCountryPlaceholder',
        type: 'text',
        validations: ['isRequired', 'isObjectRequired'],
        value: 'country_of_birth.translated_name',
        valueProperty: true
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        fieldName: 'birth_location',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.birthLocation',
        type: 'text',
        validations: ['isRequired', 'lettersOnly']
      },
      {
        capitalizeValue: true,
        countrySpecificField: ['BE', 'DE', 'FR'],
        className: 'display-none',
        fieldName: 'birth_department',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.birthDepartment',
        type: 'text',
        validations: ['birthDepartment']
      }
    ],
    officialDocuments: [
      {
        capitalizeValue: false,
        className: 'display-none',
        countrySpecificField: ['BE'],
        fieldName: 'ssn',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.socialSecurityNumber',
        type: 'text',
        validations: ['isRequired']
      },
      {
        capitalizeValue: false,
        className: 'display-none',
        countrySpecificField: ['DE', 'FR'],
        fieldName: 'ssn',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.socialSecurityNumber',
        type: 'text',
        validations: ['isRequired', 'fixed15']
      },
      {
        capitalizeValue: false,
        className: 'display-none',
        countrySpecificField: ['BE', 'DE', 'FR'],
        fieldName: 'cpr_number',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.passportOrIdentityCard',
        type: 'text',
        validations: ['isRequired']
      },
      {
        capitalizeValue: false,
        className: 'custom-date-input',
        countrySpecificField: ['BE', 'DE', 'FR'],
        fieldName: 'id_expiry_date',
        hasIcon: false,
        label: 'profile:identityTab.idExpiryDate',
        type: 'date',
        dateRange: { min: 'today', max: null },
        validations: ['isRequired', 'minDateValidation']
      },
      {
        capitalizeValue: false,
        className: 'display-none',
        countrySpecificField: ['DE', 'FR'],
        fieldName: 'siret_number',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.siretNumber',
        type: 'text',
        validations: []
      },
      {
        capitalizeValue: false,
        countrySpecificField: ['BE'],
        fieldName: 'company_registration_number_status',
        hasIcon: false,
        selectTranslationKey: 'profile:identityTab',
        label: 'profile:identityTab.companyRegistrationNumberStatus',
        type: 'select',
        validations: []
      },
      {
        capitalizeValue: false,
        className: 'display-none',
        countrySpecificField: ['BE'],
        fieldName: 'siret_number',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.btw',
        type: 'text',
        validations: []
      },
      {
        capitalizeValue: false,
        className: 'display-none',
        countrySpecificField: ['IE', 'UK'],
        fieldName: 'national_insurance_number',
        hasIcon: true,
        icon: 'CHECKMARK',
        label: 'profile:identityTab.nationalInsuranceNumber',
        type: 'text',
        validations: ['nationalInsuranceNumber']
      }
    ]
  },
  contact: {
    homeAddress: [
      {
        capitalizeValue: true,
        countrySpecificField: ['FR', 'BE', 'DE'],
        className: 'display-none',
        fieldName: 'street_name',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.streetName',
        type: 'text',
        validations: ['isRequired', 'lettersOnly']
      },
      {
        capitalizeValue: true,
        countrySpecificField: ['FR', 'BE', 'DE'],
        className: 'display-none',
        fieldName: 'street_number',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.streetNumber',
        type: 'text',
        validations: []
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        countrySpecificField: ['IE', 'UK'],
        fieldName: 'address_line1',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.line1',
        type: 'text',
        validations: ['isRequired']
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        fieldName: 'address_line2',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.line2',
        type: 'text',
        validations: []
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        countrySpecificField: ['IE', 'UK'],
        fieldName: 'address_line3',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.line3',
        type: 'text',
        validations: []
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        fieldName: 'city',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.city',
        type: 'text',
        validations: ['isRequired']
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        fieldName: 'country',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: true,
        isReadOnly: true,
        label: 'profile:contactTab.country',
        translateValue: true,
        type: 'text',
        validations: ['isRequired']
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        countrySpecificField: ['BE'],
        fieldName: 'regions',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        isReadOnly: true,
        label: 'profile:contactTab.pitchingRegions',
        selectTranslationKey: 'profile:contactTab',
        translateValue: true,
        type: 'text',
        validations: []
      },
      {
        capitalizeValue: false,
        className: 'display-none',
        fieldName: 'postcode',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.postcode',
        type: 'text',
        validations: ['isRequired']
      }
    ],
    emergencyContact: [
      {
        capitalizeValue: true,
        className: 'display-none',
        fieldName: 'emergency_name',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.fullName',
        type: 'text',
        validations: ['isRequired', 'lettersOnly']
      },
      {
        capitalizeValue: true,
        className: 'display-none',
        fieldName: 'emergency_phone_number',
        hasIcon: true,
        icon: 'CHECKMARK',
        isUk: false,
        label: 'profile:contactTab.telephone',
        type: 'number',
        validations: ['isRequired', 'phoneNumber']
      }
    ]
  },
  bankDetails: [
    {
      iBanMaskValue: true,
      fieldName: 'account_number',
      label: 'profile:bankDetailsTab.registrationNumber',
      type: 'text',
      className: 'display-none',
      hasIcon: true,
      icon: 'CHECKMARK',
      validations: ['isRequired', 'accountNumberFormat'],
      onChangeHandler: 'handleChangeBankAccount',
      countrySpecificField: ['IE', 'BE', 'DE', 'FR']
    },
    {
      fieldName: 'bic',
      countrySpecificField: ['IE', 'BE', 'DE', 'FR'],
      label: 'profile:bankDetailsTab.bic',
      type: 'text',
      className: 'display-none',
      hasIcon: true,
      icon: 'CHECKMARK',
      validations: [],
      isReadOnly: true
    },
    {
      fieldName: 'account_number',
      label: 'profile:bankDetailsTab.accountNumber',
      type: 'text',
      className: 'display-none',
      hasIcon: true,
      icon: 'CHECKMARK',
      validations: ['isRequired'],
      onChangeHandler: 'handleChangeBankAccount',
      countrySpecificField: ['UK']
    },
    {
      fieldName: 'bank_sort_code',
      label: 'profile:bankDetailsTab.sortCode',
      type: 'text',
      className: 'display-none',
      hasIcon: true,
      icon: 'CHECKMARK',
      validations: ['isRequired'],
      onChangeHandler: 'handleChangeBankAccount',
      countrySpecificField: ['UK']
    },
    {
      fieldName: 'bank_name',
      label: 'profile:bankDetailsTab.bankName',
      type: 'text',
      className: 'display-none',
      hasIcon: true,
      icon: 'CHECKMARK',
      validations: [],
      isReadOnly: true
    },
    {
      fieldName: 'account_holder_name',
      label: 'profile:bankDetailsTab.accountHolderName',
      type: 'text',
      className: 'display-none',
      hasIcon: true,
      icon: 'CHECKMARK',
      validations: ['isRequired']
    }
  ]
};
